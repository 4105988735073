import React, { useState, useEffect } from 'react';
import { View, Text, SafeAreaView, Keyboard, Image, StyleSheet, TouchableWithoutFeedback, Linking } from 'react-native';
import { Button, Card, Input, Overlay } from 'react-native-elements';
import { Picker } from 'react-native';
import { Icon } from 'react-native-elements';
import { Share } from 'react-native';
// import LoginScreen from './LoginScreen.js';

import styles from './styles.js';
// import { GoogleAnalyticsTracker } from 'react-native-google-analytics-bridge';

// const tracker = new GoogleAnalyticsTracker('G-PSTRCBJN36');

const splashImage = require('./assets/splash-image.jpg');
const logoImage = require('./assets/logo.jpg');

// const [showOverlay, setShowOverlay] = useState(false);

//   const handleLoginButtonClick = () => {
//     setShowOverlay(true);
//   }

//   const handleLoginScreenClose = () => {
//     setShowOverlay(false);
//   }


export default function MealPlanApp() {
  const [age, setAge] = useState('30');
  const [gender, setGender] = useState('male');
  const [country, setCountry] = useState('Indian');
  const [conditions, setConditions] = useState('');
  const [mealType, setMealType] = useState('lunch');
  const [mealPlan, setMealPlan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const popularDiets = [
    'Mediterranean',
    'Paleo',
    'Ketogenic',
    'Vegan',
    'Vegetarian',
    'Gluten-free',
    'Pescatarian',
    'Low-fat',
    'Dairy-free',
    'Egg-free',
    'Nut-free',
    'Shellfish-free',
  ];
  const [selectedDiet, setSelectedDiet] = useState('Vegetarian');
  const handleDietChange = (value) => {
    setSelectedDiet(value);
  };

  // For favorites tracking.
  const [favoriteMeals, setFavoriteMeals] = useState([]);


  // function shareOnWhatsApp() {
  //   const message = `Here's my meal plan for today:\n\n${mealPlan}`;
  //   const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}`;
  //   Linking.openURL(whatsappUrl).catch(() => {
  //     console.log('Unable to open WhatsApp');
  //   });
  // }
  function shareOnWhatsApp() {
    const message = `🍲 Here's my meal plan for today:\n\n${mealPlan}\n\nCheck out the Baawarchi app for more meal ideas: https://www.baawarchi.app`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}`;
    Linking.openURL(whatsappUrl).catch(() => {
      console.log('Unable to open WhatsApp');
    });
  }


  // function shareMealPlan() {
  //   const message = `Here's my meal plan for today:\n\n${mealPlan}`;
  //   Share.share({
  //     message: message,
  //   }).catch((error) => console.log(error));
  // }

//   function shareMealPlan() {
//     const message = `🍴 Here's my meal plan for today:\n\n${mealPlan}\n\nCheck out Baawarchi for more meal ideas: www.bawarchi.app`;
//     Share.share({
//       message,
//     })
//       .then((result) => console.log(result))
//       .catch((error) => console.log(error));
//   }

function shareMealPlan() {
    const message = `🍴 Here's my meal plan for today:\n\n${mealPlan}\n\nCheck out Baawarchi for more meal ideas: www.bawarchi.app`;
    Share.share({
      message,
    })
      .then((result) => {
        console.log(result);
        setFavoriteMeals((prevFavoriteMeals) => [...prevFavoriteMeals, mealPlan]); // Add the current meal plan to the favoriteMeals state
      })
      .catch((error) => console.log(error));
    //   tracker.trackEvent('Button', 'Click', { label: 'Share-App-from-MealPlanPopup' });
  }
  

  function generateMealPlan() {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      const selectedText = "The quick brown fox jumps over the lazy dog.";
      // Call to chat API
      // Call the ChatGPT API to get the meaning of the selected text
      const user_age = { age };
      const user_gender = { gender };
      const user_cuisine = { country };
      const user_conditions = { conditions };
      const user_meal_type = { mealType };


      fetch("https://api.openai.com/v1/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 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",
        },
        body: JSON.stringify({
          prompt: "Make a few healthy suggestions for cuisine type " + user_cuisine.country + " for " + user_meal_type.mealType +
            " with user having " + user_conditions.conditions + ".Only choose ones that are easy to make within 30 mins.Limit to 3 options.",
          model: "text-davinci-003",
          max_tokens: 200,
          temperature: 0.7,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.info("ChatGPT response: ", data);
          if (data && data.choices && data.choices[0] && data.choices[0].text) {
            console.log("ChatGPT response: ", data);
            // Display the meaning of the selected text to the user
            console.log(data.choices[0].text);
            const mealPlan = data.choices[0].text;
            setMealPlan(mealPlan);
          } else {
            console.error("ChatGPT API returned empty or invalid response: ", data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error calling ChatGPT: ", error);
          setIsLoading(false);
        });
    });
    // track a form submission
    // tracker.trackEvent('Form', 'Submit', { label: 'Generate Meal Plan', value: 1 });
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.banner}>
        <Text style={styles.title}>Baawarchi V1</Text>
        <Image source={logoImage} style={styles.logo} />
        <Text style={styles.caption}>Your personal chef (can go wrong sometimes!)</Text>
      </View>
      <View style={styles.mainSection}>
        <View style={styles.formContainer}>
          {/* <Input style={styles.input}
            label="Age"
            placeholder="Enter your age"
            value={age}
            onChangeText={setAge}
            keyboardType="numeric"
            editable={true}
          />
          <Input
            label="Gender"
            placeholder="Enter your gender"
            value={gender}
            onChangeText={setGender}
          /> */}
          <Text style={{ marginBottom: 10 }}>Select Cuisine Style</Text>
          <Picker style={styles.picker}
            selectedValue={country}
            onValueChange={setCountry}
          >

            <Picker.Item label="American" value="American" />
            <Picker.Item label="Asian" value="Asian" />
            <Picker.Item label="Indian" value="Indian" />
            <Picker.Item label="Italian" value="Italian" />
            <Picker.Item label="No Preference" value="No Preference" />
            {/* Add more cuisine options here */}
          </Picker>
          <Text style={{ marginTop: 10 }}>Select a diet:</Text>
          <Picker style={styles.picker}
            selectedValue={selectedDiet}
            onValueChange={handleDietChange}
          >
            {popularDiets.map((diet) => (
              <Picker.Item key={diet} label={diet} value={diet} />
            ))}
          </Picker>
          <Text style={{ marginTop: 10 }}>Meal Type</Text>
          <Picker style={styles.picker}
            selectedValue={mealType}
            onValueChange={setMealType}
          >
            <Picker.Item label="Breakfast" value="breakfast" />
            <Picker.Item label="Lunch" value="lunch" />
            <Picker.Item label="Dinner" value="dinner" />
            <Picker.Item label="Snack" value="snack" />
            <Picker.Item label="Supper" value="supper" />
            {/* Add more meal type options here */}
          </Picker>
          <Input 
            label="Other preferences"
            placeholder="Vegan, Allergies etc."
            value={conditions}
            onChangeText={setConditions}
          />
          <Button
            title="Get Suggestions"
            onPress={generateMealPlan}
            loading={isLoading}
            buttonStyle={{ backgroundColor: '#1E88E5', marginBottom: 20 }}
          />
          {mealPlan ? (
            <Overlay isVisible={true}  overlayStyle={{ width: '90%' }}>
              <View style={{ padding: 20, width: "90%" }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                  <Button
                    title="Share"
                    onPress={shareMealPlan}
                    buttonStyle={{ backgroundColor: '#25D366' }}
                  />
                {/* <Icon
                    name="heart"
                    type="font-awesome"
                    size={24}
                    onPress={() => {
                      setFavoriteMeals([...favoriteMeals, mealPlan]);
                      setMealPlan('');
                    }}
                    containerStyle={styles.heartIcon}
                  />                   */}
                  <Icon
                    name="close"
                    color="blue"
                    type="font-awesome"
                    size={24}
                    onPress={() => setMealPlan('')}
                    containerStyle={styles.closeIcon}
                  />
                </View>
                <Text style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>
                  Here is a {mealType} for {country} cuisine and {selectedDiet} diet.
                </Text>
                <Text>{mealPlan}</Text>
              </View>
            </Overlay>
          ) : null}
        </View>
        {/* <Button title="Login" onPress={handleLoginButtonClick} />

      <Overlay isVisible={showOverlay}>
        <LoginScreen onClose={handleLoginScreenClose} />
      </Overlay> */}
      </View>
    </SafeAreaView>
  );
}