import { StyleSheet } from 'react-native';
import Constants from 'expo-constants';
export default StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#F2FBEF',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      paddingTop: Constants.statusBarHeight,
    },
    banner: {
      fontFamily: 'cursive',
      backgroundColor: '#5DA271',
      height: 140,
      width: '90%',
      alignItems: 'center',
      borderRadius: 10,
    },
    mainSection: {
      width: '90%',
    },
    logo: {
      width: 60,
      height: 60,
      resizeMode: 'contain',
    },
    caption: {
      fontSize: 12,
      fontWeight: 'bold',
      fontStyle: 'italic',
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 10,
    },
    formContainer: {
      width: '90%',
      alignItems: 'left',
    },
    input: {
      height: 30,
      width: '100%'
    },
    button: {
      backgroundColor: '#1E88E5',
      marginBottom: 10,
    },
    buttonText: {
      color: 'white',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 20,
      backgroundColor: '#1E88E5',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    headerTitle: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
    },
    closeIcon: {
      backgroundColor: 'white',
      borderRadius: 100,
      padding: 5,
    },
    picker: {
      borderRadius: 10,
      backgroundColor: '#fff',
      paddingHorizontal: 10,
      paddingVertical: 5,
      marginBottom: 10,
    },
  
  });