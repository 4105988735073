import React, { useState, useEffect } from 'react';
import MealPlanApp from './MealPlanApp';
import FavoritesScreen from './FavoritesScreen';
import RecipeSearchScreen from './RecipeSearchScreen';
import About from './About';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
// import { GlobalFont } from 'react-native-global-font';
// import * as Font from 'expo-font';
// import styles from './styles.js';

import { Icon } from 'react-native-elements';

import { GoogleAnalyticsTracker } from 'react-native-google-analytics-bridge';
import ChatScreen from './ChatScreen';

const tracker = new GoogleAnalyticsTracker('G-PSTRCBJN36');

const MainStackNavigator = createStackNavigator();

function MainStackScreen() {
  const [favoriteMeals, setFavoriteMeals] = useState([]);

  const addToFavorites = (meal) => {
    setFavoriteMeals([...favoriteMeals, meal]);
  };

  return (
    <MainStackNavigator.Navigator>
      <MainStackNavigator.Screen
        name="ChefChat"
        component={ChatScreen}
        options={{ title: 'Chef Chat' }}
      />
      {/* <MainStackNavigator.Screen
        name="Planner"
        component={MealPlanApp}
        options={{title: 'Planner' }}
      /> */}
      {/* <MainStackNavigator.Screen
        name="Favorites"
        options={{ title: 'Favorites' }}
      >
        {() => <FavoritesScreen favoriteMeals={favoriteMeals} />}
      </MainStackNavigator.Screen> */}
      <MainStackNavigator.Screen
        name="Magic Meals"
        component={RecipeSearchScreen}
        options={{ title: 'Recipe Search by items' }}
      />
      <MainStackNavigator.Screen
        name="About"
        component={About}
        options={{ title: 'About' }}
      />
    </MainStackNavigator.Navigator>
  );
}


const MainBottomTabNavigator = createBottomTabNavigator();

function MainTabNavigator() {
  return (
    <MainBottomTabNavigator.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color, size }) => {
          let iconName;

          if (route.name === 'Planner') {
            iconName = 'salad';
          } else if (route.name === 'Favorites') {
            iconName = 'heart';
          } else if (route.name === 'About') {
            iconName = 'info-circle';
          } else if (route.name === 'Magic Meals') {
            iconName = 'magic';
          } else if (route.name === 'ChefChat') {
            iconName = 'comments';
          }

          return <Icon name={iconName} type="font-awesome" color={color} size={size} />;
        },
      })}
      tabBarOptions={{
        activeTintColor: '#1E88E5',
        inactiveTintColor: 'gray',
      }}
    >
      <MainBottomTabNavigator.Screen name="ChefChat" component={ChatScreen} />
      {/* <MainBottomTabNavigator.Screen name="Planner" component={MainStackScreen} /> */}
      {/* <MainBottomTabNavigator.Screen name="Favorites" component={FavoritesScreen} /> */}
      <MainBottomTabNavigator.Screen name="Magic Meals" component={RecipeSearchScreen} />
      <MainBottomTabNavigator.Screen name="About" component={About} />
    </MainBottomTabNavigator.Navigator>
  );
}

export default function BaawarchiApp() {
  // Google analytics
  // useEffect(() => {
  //   tracker.trackScreenView('Home');
  // }, []);

  // const fontName = 'Comic Sans MS';

  // // Set the font globally
  // GlobalFont.applyGlobal(fontName);
  return (
    <NavigationContainer>
      <MainTabNavigator />
    </NavigationContainer>
  );
}
