import React,  { useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Share } from 'react-native';
// import { GoogleAnalyticsTracker } from 'react-native-google-analytics-bridge';

// const tracker = new GoogleAnalyticsTracker('G-1B5Y5ZBLD2');

function shareApp() {
    const message = `Checkout Bawarchi for a meal suggestion: https://www.bawarchi.app`;
    Share.share({
        message: message,
    }).catch((error) => console.log(error));
    // track a button click
    // tracker.trackEvent('Button', 'Click', { label: 'Share-App-from-About' });

}

const About = () => {
    // useEffect(() => {
    //     tracker.trackScreenView('About');
    //   }, []);
    return (
        <View style={styles.container}>
            <Image source={require('./assets/chef.jpg')} style={styles.logo} />
            <View style={styles.content}>
                <Text style={styles.text}>
                    Baawarchi is an experiment to help people create easy and healthy meal suggestions.
                    If you liked it, please share with your friends and family.
                </Text>
                <TouchableOpacity style={styles.shareButton}>
                    <Ionicons name="share-social" size={24} color="white" />
                    <Text style={styles.shareText} onPress={shareApp}>Share</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2FBEF',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    logo: {
        width: 100,
        height: 100,
        marginBottom: 20,
    },
    content: {
        backgroundColor: '#f0f0f0',
        borderRadius: 10,
        padding: 20,
        width: '100%',
    },
    text: {
        fontSize: 18,
        marginBottom: 20,
    },
    shareButton: {
        backgroundColor: '#1E88E5',
        padding: 10,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    shareText: {
        color: 'white',
        fontSize: 18,
        marginLeft: 10,
    },
});

export default About;
