import React, { useState, useEffect } from 'react';
import { View, Text, SafeAreaView, Keyboard, Image, StyleSheet, TouchableWithoutFeedback, Linking } from 'react-native';
import { Button, Card, Input, Overlay } from 'react-native-elements';
import { Picker, ActivityIndicator } from 'react-native';
import { Icon } from 'react-native-elements';
import { Share } from 'react-native';

import styles from './styles.js';

export default function RecipeSearchScreen() {
    const [vegetables, setVegetables] = useState('peas');
    const [protein, setProtein] = useState('Eggs');
    const [prepTime, setPrepTime] = useState('30 mins');
    const [country, setCountry] = useState('No Preference');
    const [isLoading, setIsLoading] = useState(false);
    const [mealPlan, setMealPlan] = useState('');

    function shareMealPlan() {
        const message = `🍴 Here's my meal plan for today:\n\n${mealPlan}\n\nCheck out Baawarchi for more meal ideas: www.bawarchi.app`;
        Share.share({
            message,
        })
            .then((result) => {
                console.log(result);
                setFavoriteMeals((prevFavoriteMeals) => [...prevFavoriteMeals, mealPlan]); // Add the current meal plan to the favoriteMeals state
            })
            .catch((error) => console.log(error));
        //   tracker.trackEvent('Button', 'Click', { label: 'Share-App-from-MealPlanPopup' });
    }

    function handleSearch() {
        setIsLoading(true);

        return new Promise((resolve, reject) => {

            fetch("https://api.openai.com/v1/completions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 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",
                },
                body: JSON.stringify({
                    prompt: `You are a recipe database." +
          "Ingredients available are ${vegetables} & protein choice is ${protein} & prep and cook Time should be around ${prepTime}." + 
          "Cusine choice: ${country}.Limit to 1 result and only those responses which will be limited to 200 words or less." +
          " Include a link to the source.Show instructions first and ingredients later in the order.`,
                    model: "text-davinci-003",
                    max_tokens: 300,
                    temperature: 0.8,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.info("ChatGPT response: ", data);
                    if (data && data.choices && data.choices[0] && data.choices[0].text) {
                        console.log("ChatGPT response: ", data);
                        // Display the meaning of the selected text to the user
                        console.log(data.choices[0].text);
                        const mealPlan = data.choices[0].text;
                        setMealPlan(mealPlan);
                    } else {
                        console.error("ChatGPT API returned empty or invalid response: ", data);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Error calling ChatGPT: ", error);
                    setIsLoading(false);
                });
        });
    }

    return (
        <View style={{ width: "90%", marginLeft: 20, backgroundColor: '#F2FBEF' }}>
            <Text style={{ marginTop: 10 }}>Enter the ingredients:</Text>
            <Input style={styles.input} placeholder="Vegetables" value={vegetables} onChangeText={setVegetables} />
            {/* <Input style={styles.input} placeholder="Protein" value={protein} onChangeText={setProtein} />
      <Input style={styles.input} placeholder="Prep Time" value={prepTime} onChangeText={setPrepTime} /> */}
            <Text style={{ marginBottom: 10, marginTop: 10 }}>Select Protein</Text>
            <Picker style={styles.picker}
                selectedValue={protein}
                onValueChange={(itemValue, itemIndex) => setProtein(itemValue)}
            >
                <Picker.Item label="Tofu" value="Tofu" />
                <Picker.Item label="Cottage Cheese(Paneer)" value="Paneer" />
                <Picker.Item label="Lentils or Beans" value="Lentils or Beans" />
                <Picker.Item label="Eggs" value="Eggs" />
                <Picker.Item label="Fish" value="Fish" />
                <Picker.Item label="Shrimp" value="Shrimp" />
                <Picker.Item label="Chicken" value="Chicken" />
                <Picker.Item label="Meat" value="Meat" />
                <Picker.Item label="No protein" value="No protein" />
            </Picker>
            <Text style={{ marginBottom: 10 }}>Select Cuisine Style</Text>
          <Picker style={styles.picker}
            selectedValue={country}
            onValueChange={setCountry}
          >

            <Picker.Item label="American" value="American" />
            <Picker.Item label="Asian" value="Asian" />
            <Picker.Item label="Indian" value="Indian" />
            <Picker.Item label="Italian" value="Italian" />
            <Picker.Item label="No Preference" value="No Preference" />
            {/* Add more cuisine options here */}
          </Picker>
            <Text style={{ marginBottom: 10 }}>Prep & Cook time</Text>
            <Picker style={styles.picker} selectedValue={prepTime}
                onValueChange={(itemValue, itemIndex) => setPrepTime(itemValue)}
            >
                <Picker.Item label="20 mins" value="20 mins" />
                <Picker.Item label="30 mins" value="30 mins" />
                <Picker.Item label="45 mins" value="45 mins" />
            </Picker>
            <Button title="Show me options" onPress={handleSearch} />
            {isLoading ? (
                <ActivityIndicator size="large" color="#0000ff" />
            ) : mealPlan ? (
                <Overlay isVisible={true} overlayStyle={{ width: '90%' }}>
                    <View style={{ padding: 20, width: "90%" }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <Button
                                title="Share"
                                onPress={shareMealPlan}
                                buttonStyle={{ backgroundColor: '#25D366' }}
                            />
                            {/* <Icon
            name="heart"
            type="font-awesome"
            size={24}
            onPress={() => {
              setFavoriteMeals([...favoriteMeals, mealPlan]);
              setMealPlan('');
            }}
            containerStyle={styles.heartIcon}
          />                   */}
                            <Icon
                                name="close"
                                color="blue"
                                type="font-awesome"
                                size={24}
                                onPress={() => setMealPlan('')}
                                containerStyle={styles.closeIcon}
                            />
                        </View>
                        <Text style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>
                            Here are some options for you.
                        </Text>
                        <Text>{mealPlan}</Text>
                    </View>
                </Overlay>
            ) : null}
        </View>
    );
}