import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

const ChatScreen = () => {
  const [input, setInput] = useState('Wazzup');
  const [conversation, setConversation] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const CHATGPT_API_KEY = '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';

  const handleMessageSubmit = async () => {
    setIsProcessing(true);
    const response = await fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CHATGPT_API_KEY}`,
      },
      body: JSON.stringify({
        prompt: `User: ${input}\nChef:`,
        model: "text-davinci-003",
        max_tokens: 200,
        temperature: 0.7,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      }),
    });

    const data = await response.json();
    const chefResponse = data.choices[0].text.replace('Chef:', '').trim();

    setConversation([...conversation, { text: 'You: ' + input, isUser: true }, { text: 'Chef: ' + chefResponse, isUser: false }]);
    setInput('');
    setIsProcessing(false);
  };

  return (
    <View style={styles.container}>
        <ScrollView>
      <View style={styles.chatContainer}>
        {conversation.map((message, index) => (
          <View key={index} style={[styles.message, message.isUser ? styles.userMessage : styles.chefMessage]}>
            <Text>{message.text}</Text>
          </View>
        ))}
      </View>
      </ScrollView>
      <View style={styles.inputContainer}>
        <TextInput style={styles.input} value={input} onChangeText={setInput} />
        <TouchableOpacity style={styles.button} onPress={handleMessageSubmit} disabled={isProcessing}>
          {isProcessing ? <Text style={styles.buttonText}>Thinking...</Text> : <Text style={styles.buttonText}>Send</Text>}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chatContainer: {
    flex: 1,
    padding: 10,
    backgroundColor: 'beige',
  },
  message: {
    maxWidth: '80%',
    padding: 10,
    marginVertical: 5,
    borderRadius: 10,
  },
  userMessage: {
    // backgroundColor: '#E8F5E9',
    alignSelf: 'flex-start',
  },
  chefMessage: {
    // backgroundColor: '#FFFFFF',
    alignSelf: 'flex-end',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  input: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    marginRight: 10,
  },
  button: {
    backgroundColor: '#007AFF',
    borderRadius: 20,
    padding: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});

export default ChatScreen;
